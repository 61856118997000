<template>
  <div>
    <v-card-title class="text-xl-h4 font-weight-regular">
      <p>
        You are about to open the Contact
        <span class="ap-blue--text">{{ entityFullName }}</span>
        {{ `(ID${entity.id})` }}
      </p>
    </v-card-title>
    <v-card-text>
      <p>
        Since you are not the owner of the record you do not have editing
        permissions.
        <span class="ap-blue--text">Contact your Admin</span>
        if you have questions.
      </p>
    </v-card-text>
    <v-card-text>
      <v-divider />
      <v-checkbox
        label="Don't show this message once again"
        color="blue"
        hide-details
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        color="blue"
        class="text-none font-weight-regular rounded-0"
        @click="takeMeBack"
      >
        Take me back
      </v-btn>
      <v-btn
        color="blue"
        class="text-none font-weight-regular rounded-0 ap-white--text"
        @click="confirm"
      >
        Confirm
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    entity: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    entityFullName() {
      return `${this.entity.firstname} ${this.entity.lastname}`
    },
  },
  methods: {
    takeMeBack() {
      this.$emit('dialogAction', {
        action: 'saveForm',
        source: 'WebUI',
        isSaveAllowed: false,
      })
    },
    confirm() {
      this.$emit('dialogAction', {
        action: 'openForm',
        source: 'WebUI',
        data: {
          type: 'contact',
          id: this.entity.id,
        },
      })
    },
  },
}
</script>
