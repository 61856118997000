<template>
  <div>
    <v-card-title class="text-xl-h4 font-weight-regular">
      <p class="mb-0">
        You are about to open the Contact
        <span class="ap-blue--text">{{ entityFullName }}</span>
        {{ `(ID${entity.id})` }}
      </p>
    </v-card-title>
    <v-card-subtitle>
      <v-card class="mt-4" outlined>
        <v-card-text class="caption ma-0 pb-0"> Confict found </v-card-text>
        <v-card-text class="pt-0 body-1 ap-black--text">
          <p class="font-weight-light">
            What you entered is different from the existing Contact
            <span class="ap-blue--text">{{ entityFullName }}</span>
            {{ `(ID${entity.id})` }}. Select which to keep:
          </p>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col>
              <p class="caption mb-1">Business Phone:</p>
              <v-radio-group v-model="selected.phone" class="mt-0 d-flex" row>
                <v-radio :value="entity.newContactDetails.phone">
                  <template #label>
                    <div>
                      New value:
                      <span class="font-weight-bold">
                        {{ entity.newContactDetails.phone }}
                      </span>
                    </div>
                  </template>
                </v-radio>
                <v-radio :value="entity.contactDetails.phone">
                  <template #label>
                    <div>
                      Current:
                      <span class="font-weight-bold">
                        {{ entity.contactDetails.phone }}
                      </span>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-divider />
          <v-row>
            <v-col>
              <p class="caption mt-1 mb-1">City:</p>
              <v-radio-group v-model="selected.city" class="mt-0" row>
                <v-radio :value="entity.newContactDetails.city">
                  <template #label>
                    <div>
                      New value:
                      <span class="font-weight-bold">
                        {{ entity.newContactDetails.city }}
                      </span>
                    </div>
                  </template>
                </v-radio>
                <v-radio :value="entity.contactDetails.city">
                  <template #label>
                    <div>
                      Current:
                      <span class="font-weight-bold">
                        {{ entity.contactDetails.city }}
                      </span>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-subtitle>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        color="blue"
        class="text-none font-weight-regular rounded-0"
        @click="takeMeBack"
      >
        Take me back
      </v-btn>
      <v-btn
        color="blue"
        class="text-none font-weight-regular rounded-0 ap-white--text"
        @click="applyUpdates"
      >
        Apply updates
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    entity: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selected: {
        phone: null,
        city: null,
      },
    }
  },
  computed: {
    entityFullName() {
      return `${this.entity.firstname} ${this.entity.lastname}`
    },
  },
  mounted() {
    this.$emit('setWidth', '562')
  },
  methods: {
    takeMeBack() {
      this.$emit('dialogAction', {
        action: 'saveForm',
        source: 'WebUI',
        isSaveAllowed: false,
      })
    },
    applyUpdates() {
      this.$emit('dialogAction', {
        action: 'openForm',
        source: 'WebUI',
        data: {
          type: 'contact',
          id: this.entity.id,
          selected: this.selected,
        },
      })
    },
  },
}
</script>

<style scoped>
.v-input--radio-group.v-input--radio-group--row .v-radio {
  width: 50%;
  margin-right: 0;
}
</style>
