import { render, staticRenderFns } from "./OnEntryFindDuplicatesDialogMatch.vue?vue&type=template&id=3ade0ffa&"
import script from "./OnEntryFindDuplicatesDialogMatch.vue?vue&type=script&lang=js&"
export * from "./OnEntryFindDuplicatesDialogMatch.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCardActions,VCardText,VCardTitle,VCheckbox,VDivider,VSpacer})
