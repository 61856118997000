<template>
  <div>
    <v-card-title class="text-xl-h4 font-weight-regular">
      <p>
        The Contact
        <span class="ap-blue--text">{{ entityFullName }}</span> already exist
        {{ `(ID${entity.id})` }}
      </p>
    </v-card-title>
    <v-card-text>Are you sure you want to create a new one?</v-card-text>
    <v-card-text>
      The duplicate you are about to create will be sent to your data
      administration team for review and possible merge
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        color="blue"
        class="text-none font-weight-regular rounded-0"
        @click="onClick(false)"
      >
        Take me back
      </v-btn>
      <v-btn
        color="blue"
        class="text-none font-weight-regular rounded-0 ap-white--text"
        @click="onClick(true)"
      >
        Create & Send to Admin
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    entity: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    entityFullName() {
      return `${this.entity.firstname} ${this.entity.lastname}`
    },
  },
  methods: {
    onClick(isAllowed) {
      this.$emit('dialogAction', {
        action: 'saveForm',
        source: 'WebUI',
        isSaveAllowed: isAllowed,
      })
    },
  },
}
</script>
