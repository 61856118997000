<template>
  <v-card outlined>
    <div class="pa-2">
      <div class="font-weight-medium">
        <template v-if="entity === 'Account'">
          {{ item.Name }}
        </template>
        <template v-else> {{ item.FirstName }} {{ item.LastName }} </template>
      </div>

      <div
        v-if="entity !== 'Account' && item.Email"
        class="text-caption mt-1 d-flex align-center"
      >
        <v-icon small class="mr-1">$mdi-email</v-icon>{{ item.Email }}
      </div>
    </div>

    <v-divider />

    <div
      class="text-center font-weight-medium text-caption py-1 cursor-pointer ap-dark-blue-10 ap-dark-blue--text"
      @click="$emit('use', item.Id)"
    >
      Use
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    entity: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>
