<template>
  <v-container fluid class="pa-0">
    <div class="d-flex pa-4" style="max-height: 100vh; position: relative">
      <div style="width: 400px" class="mr-3">
        <DevOnEntryForm v-if="isSPA && false" class="ma-4" />
        <div class="pa-6 ap-white elevation-1 rounded">
          <div class="text-h6 mb-3">New {{ entity }}</div>

          <ApLoading v-if="isLoadingForm" text="Loading Form ..." />

          <div v-else>
            <v-text-field
              v-for="(field, index) in form"
              :key="index"
              v-model="field.value"
              outlined
              dense
              hide-details
              class="mb-3"
              :label="field.label"
              @input="onFieldValueChanged"
            />

            <v-btn block color="primary" @click="create">Create</v-btn>
          </div>
          <!-- <div>
            <v-text-field
              v-model="firstname"
              outlined
              dense
              hide-details
              class="mb-3"
              label="First Name"
              @input="onFieldValueChanged"
            />
            <v-text-field
              v-model="lastname"
              outlined
              dense
              hide-details
              class="mb-3"
              label="Last Name"
              @input="onFieldValueChanged"
            />
            <v-text-field
              v-model="phone"
              outlined
              dense
              hide-details
              class="mb-3"
              label="Phone"
              @input="onFieldValueChanged"
            />
          </div> -->
        </div>
      </div>

      <div>
        <div
          class="elevation-1 rounded ap-white pa-6"
          style="max-height: 100%; overflow-y: auto"
        >
          <!-- <OnEntryFindDuplicatesNoResults
            v-if="!activeViewType && !appEventType"
          /> -->

          <div style="width: 450px">
            <!-- <OnEntryFindDuplicatesHeader /> -->
            <ApLoading
              v-if="isLoadingDuplicates"
              text="Finding duplicates ..."
            />

            <template
              v-else-if="isFormFilled && duplicates && duplicates.length > 0"
            >
              <div v-if="isFormFilled" class="text-h6 mb-3">
                Possible Duplicates
              </div>

              <OnEntryDuplicatesList
                :entity="entity"
                :items="duplicates"
                @use="open"
              />
            </template>

            <!-- <OnEntryFindDuplicatesList v-if="duplicates" @use="open" /> -->
            <OnEntryFindDuplicatesNotFound
              v-else-if="isFormFilled && duplicates && duplicates.length === 0"
            />

            <div
              v-else
              class="d-flex flex-column no-results align-center justify-center"
            >
              <v-icon class="d-flex text-xs-center" size="60" color="ap-blue">
                $mdi-magnify
              </v-icon>
              <div class="ap-blue--text font-weight-medium">
                Fill in the fields to find duplicates
              </div>
            </div>

            <OnEntryFindDuplicatesDialog
              v-model="isDialogActive"
              :entity="dialogContent"
              @close="closeDialog"
            />
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
// import { sendBusMessage } from '@/services/message-bus'
// import OnEntryFindDuplicatesHeader from './components/find-duplicates/OnEntryFindDuplicatesHeader.vue'
import OnEntryFindDuplicatesDialog from './components/find-duplicates/OnEntryFindDuplicatesDialog'
// import OnEntryFindDuplicatesNoResults from './components/find-duplicates/OnEntryFindDuplicatesNoResults'
// import OnEntryFindDuplicatesList from './components/find-duplicates/OnEntryFindDuplicatesList'
import OnEntryFindDuplicatesNotFound from './components/find-duplicates/OnEntryFindDuplicatesNotFound'
import OnEntryDuplicatesList from './components/OnEntryDuplicatesList'
import ApLoading from '@/components/common/ApLoading'
import { isSPA } from '@/utils/env'
import { getMetadata, searchDuplicates } from '@/api/on-entry'
import { debounce } from 'lodash-es'
import axios from 'axios'

export default {
  components: {
    ApLoading,
    // OnEntryFindDuplicatesHeader,
    OnEntryFindDuplicatesDialog,
    // OnEntryFindDuplicatesNoResults,
    // OnEntryFindDuplicatesList,
    OnEntryFindDuplicatesNotFound,
    OnEntryDuplicatesList,
    DevOnEntryForm: () => import('@/components/dev/DevOnEntryForm.vue'),
  },
  data() {
    return {
      isSPA,
      formMetadata: [],
      form: {},
      firstname: null,
      lastname: null,
      phone: null,
      duplicates: null,
      isLoadingDuplicates: false,
      isErrorLoadingDuplicates: false,
      isLoadingForm: false,
      requests: [],
    }
  },
  computed: {
    ...mapState('auth', ['contextMessage']),
    ...mapState('on-entry', [
      'activeViewType',
      'appEventType',
      'dialogContent',
      'isDialogActive',
    ]),
    ...mapGetters('on-entry', ['isBlockedByAdmin', 'isWarnByAdmin', 'results']),
    entity() {
      return this.contextMessage?.data?.entityName
    },
    isFormFilled() {
      for (let key in this.form) {
        if (this.form[key].value) {
          return true
        }
      }

      return false
    },
  },
  created() {
    this.getFormMetadata()
  },
  async mounted() {
    // sendBusMessage({
    //   action: 'ready',
    //   source: 'WebUI',
    // })
    // window.addEventListener(
    //   'message',
    //   (event) => {
    //     if (event.data && event.data.type) {
    //       const { type } = event.data
    //       const { inputType, inputQuery } = event.data.data
    //       this.setEventType(type)
    //       // contact, account, lead...
    //       this.setActiveViewType('OnEntryFindDuplicates')
    //       switch (type) {
    //         case 'ApFormChange':
    //           this.searchDuplicates({ inputType, inputQuery })
    //           break
    //         case 'ApFormSave':
    //           this.onFormSave(inputQuery)
    //           break
    //       }
    //     }
    //   },
    //   false
    // )
  },
  methods: {
    ...mapActions('on-entry', [
      'setEventType',
      'setActiveViewType',
      // 'searchDuplicates',
      'onFormSave',
      'closeDialog',
    ]),
    async getFormMetadata() {
      this.isLoadingForm = true
      const formByEntity = await getMetadata()
      this.form = formByEntity[this.entity]
      this.isLoadingForm = false
    },
    onFieldValueChanged: debounce(async function () {
      this.setActiveViewType('OnEntryFindDuplicates')

      const payload = {}
      for (const field in this.form) {
        if (this.form[field].value) {
          payload[field] = this.form[field].value
        }
      }

      try {
        this.isLoadingDuplicates = true
        this.duplicates = await searchDuplicates(this.entity, payload)
        this.isLoadingDuplicates = false
      } catch (error) {
        if (!axios.isCancel(error)) {
          this.isErrorLoadingDuplicates = true
        }
      }

      // this.searchDuplicates({
      //   inputType: 'contact',
      //   inputQuery: {
      //     firstname: this.firstname,
      //     lastname: this.lastname,
      //     phone: this.phone,
      //   },
      // })
    }, 200),
    open(id) {
      parent.postMessage(
        {
          action: 'open',
          entity: 'Contact',
          id,
        },
        '*'
      )
    },
    create() {
      const payload = {}
      for (let field in this.form) {
        if (this.form[field].value) {
          payload[field] = this.form[field].value
        }
      }

      parent.postMessage(
        {
          action: 'create',
          entity: this.entity,
          defaultFieldValues: payload,
        },
        '*'
      )
    },
  },
}
</script>
