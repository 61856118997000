<template>
  <div>
    <v-card-title class="text-xl-h4 font-weight-regular">
      <p>
        Contact <span class="ap-blue--text">{{ entityFullName }}</span> already
        exist {{ `(ID${entity.id})` }}
      </p>
    </v-card-title>
    <v-card-text
      >Since you cannot create a duplicate, we will now direct you to the
      existing contact.</v-card-text
    >
    <v-card-text>
      <p>
        Please <span class="ap-blue--text">contact your Admin</span> if you have
        questions.
      </p>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        color="blue"
        class="text-none font-weight-regular rounded-0"
        @click="onClick()"
      >
        Take me back
      </v-btn>
      <v-btn
        color="blue"
        class="text-none font-weight-regular rounded-0 ap-white--text"
        @click="onClick()"
      >
        Ok
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    entity: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    entityFullName() {
      return `${this.entity.firstname} ${this.entity.lastname}`
    },
  },
  methods: {
    onClick() {
      this.$emit('dialogAction', {
        action: 'saveForm',
        source: 'WebUI',
        isSaveAllowed: false,
      })
    },
  },
}
</script>
