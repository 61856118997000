<template>
  <div>
    <OnEntryDuplicatesListItem
      v-for="(item, index) in items"
      :key="index"
      :item="item"
      :entity="entity"
      :class="{ 'mb-3': index < items.length - 1 }"
      @use="$emit('use', $event)"
    />
  </div>
</template>

<script>
import OnEntryDuplicatesListItem from './OnEntryDuplicatesListItem'

export default {
  components: {
    OnEntryDuplicatesListItem,
  },
  props: {
    entity: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
}
</script>
