<template>
  <v-row v-if="activeDialog" justify="center">
    <ApDialog :value="value" max-width="420">
      <template #content>
        <component
          :is="activeDialog"
          :entity="entity"
          @setWidth="setDialogWidth($event)"
          @dialogAction="setDialogAction"
        />
      </template>
    </ApDialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { sendBusMessage } from '@/services/message-bus'
import ApDialog from '@/components/common/ApDialog'
import OnEntryFindDuplicatesDialogBlock from './OnEntryFindDuplicatesDialogBlock'
import OnEntryFindDuplicatesDialogWarn from './OnEntryFindDuplicatesDialogWarn'
import OnEntryFindDuplicatesDialogNoEditPermissions from './OnEntryFindDuplicatesDialogNoEditPermissions'
import OnEntryFindDuplicatesDialogConflictFound from './OnEntryFindDuplicatesDialogConflictFound'
import OnEntryFindDuplicatesDialogMatch from './OnEntryFindDuplicatesDialogMatch'

export default {
  components: {
    ApDialog,
    OnEntryFindDuplicatesDialogBlock,
    OnEntryFindDuplicatesDialogWarn,
    OnEntryFindDuplicatesDialogNoEditPermissions,
    OnEntryFindDuplicatesDialogConflictFound,
    OnEntryFindDuplicatesDialogMatch,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    entity: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selected: [],
    }
  },
  computed: {
    ...mapState('on-entry', [
      'appEventType',
      'itemConflictsFound',
      'itemDataMatch',
    ]),
    ...mapGetters('on-entry', [
      'isBlockedByAdmin',
      'isWarnByAdmin',
      'userHasEditPermissions',
    ]),
    activeDialog() {
      if (this.isBlockedByAdmin && this.appEventType === 'ApFormSave')
        return 'OnEntryFindDuplicatesDialogBlock'
      if (this.isWarnByAdmin && this.appEventType === 'ApFormSave')
        return 'OnEntryFindDuplicatesDialogWarn'
      if (!this.userHasEditPermissions)
        return 'OnEntryFindDuplicatesDialogNoEditPermissions'
      if (this.itemConflictsFound)
        return 'OnEntryFindDuplicatesDialogConflictFound'
      if (this.itemDataMatch) return 'OnEntryFindDuplicatesDialogMatch'

      return false
    },
  },
  methods: {
    setDialogWidth(e) {
      this.maxWidth = e
    },
    setDialogAction(event) {
      sendBusMessage(event)
      this.$emit('close')
    },
  },
}
</script>
